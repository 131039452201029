import ReactOnRails from "react-on-rails";
import MastheadLayout from "../src/components/Masterhead/Layouts/Layouts";
import { initializeGA } from "../src/utils/googleAnalytics";
import Ad from "../src/components/Ad/Ad";
import VideoAd from "../src/components/Ad/VideoAd";
import ExitIntentPopup from "../src/components/ExitIntentPopup/ExitIntentPopup";
import Section from "../src/pages/Section/Section";
import Footer from "../src/components/Footer/Footer";

initializeGA();

ReactOnRails.register({
  Ad,
  VideoAd,
  ExitIntentPopup,
  Section,
  Footer,
  MastheadLayout
});
